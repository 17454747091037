    .navbar {
      --darkmode-toggle-size: 2.5rem; /* enlarged to show texture */


      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 10%;
      background-color: inherit;

    }

  
    .navbar img {
      max-height: 5rem;
    }

    .navbar__logo {
      display: flex;
      align-items: center;
    }

    .navbar__logo img {
      max-height: 2.5rem;
    }
    
    
    .navbar__links {
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    
    .navbar-link {
      color: var(--text);
      text-decoration: none;

      font-size: 1.25rem;
      transition: 0.2s ease-in;

      position: relative;
      overflow: hidden;
    }

    .navbar-link--active {
      text-decoration: underline;
    }

    .navbar-link::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: var(--text);
      transition: width 0.2s ease-in;
    }
    
    .navbar-link:hover::after {
      width: 100%;
    }

    .navbar-link--active:hover::after {
      width: 0;
    }
    
    .darkmode-toggle-wrapper {
      --darkmode-toggle-height: var(--darkmode-toggle-size);
      --darkmode-toggle-width: calc(var(--darkmode-toggle-height) * 2);
      position: relative;
      background: var(--bg2);
      width: var(--darkmode-toggle-width);
      height: var(--darkmode-toggle-height);
      border-radius: calc(var(--darkmode-toggle-height) / 2);
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1px solid var(--gray-900);
    }
    
    .darkmode-toggle-checkbox {
      opacity: 0;
      height: var(--darkmode-toggle-height);
      width: var(--darkmode-toggle-width);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
    }
    
    .darkmode-toggle-circle {
      position: absolute;
      top: 0;
      left: 0;
      right: calc(var(--darkmode-toggle-width) / 2);
      height: var(--darkmode-toggle-height);
      width: calc(var(--darkmode-toggle-width) / 2);
      border-radius: 50%;
      background: var(--bg);
      border: 2px solid var(--primary-light);
      box-sizing: border-box;
      box-shadow: 0 0 calc(var(--darkmode-toggle-width) / 10) var(--primary-light);
      transition: 0.1s ease-in;
    }
    
    .darkmode-toggle-checkbox:checked + .darkmode-toggle-circle {
      left: calc(var(--darkmode-toggle-width) / 2);
      right: 0;
    }
    
    .darkmode-toggle-emoji {
      font-size: calc(var(--darkmode-toggle-height) * 0.6);
      line-height: 0;
    }