*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html {
  color-scheme: dark;
  accent-color: var(--sl-color-accent);
}

html[data-theme='light'] {
  color-scheme: light;
}

body {
  font-family: var(--__sl-font);
  line-height: var(--sl-line-height);
  -webkit-font-smoothing: antialiased;
  color: var(--sl-color-text);
  background-color: var(--sl-color-bg);
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

code {
  font-family: var(--__sl-font-mono);
}
