.button {
    cursor: pointer; 
    padding: .5rem 1rem;
    outline: transparent;
    border: transparent;

    border-radius: 6px;
    background-color: var(--secondary);
    color: var(--button-text);

    transition: background-color 0.3s ease;

    box-shadow: var(--shadow-sm),
         inset 0px 1px 1px rgba(215, 215, 215, 0.5);
}

.button-primary {
    background-color: var(--primary);
}

.button-secondary {
    background-color: var(--secondary);
}

.button-tertiary {  
    text-align: left;
    padding-left: 0;
    background-color: transparent;
    box-shadow: none;

    text-decoration: underline;
}

.button:hover {
    background-color: var(--secondary-dark);
}

.button-tertiary:hover {
    color: var(--gray-300);
    background-color: transparent;
}

.button-primary:hover {
    background-color: var(--primary-dark)
}

.button-secondary:hover {
    background-color: var(--secondary-dark)
}