.transcription-page {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;

    background-color: var(--bg2);

    box-shadow: var(--shadow-me);
  }

  .back-button {
    position: fixed;
    top: 5rem;
    left: 4rem;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;

    color: var(--text);
  }
  
  .transcription-page__title {
    color: var(--gray-100);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }

  
  .transcription-page__summarycontainer {
    padding: 20px;
    border: 2px solid var(--gray-400);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .transcription-page__summary {
    color: var(--gray-7);
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .transcription-page__keyinfo {
    font-size: 1.1rem;
  }

  .keyinfo-name {
    text-transform: capitalize;
  }
  
  .transcription-page__summary-container h2 {
    color: var(--gray-8);
    margin-bottom: 1rem;
  }
  
  .transcription-page__timestamps {
    padding: 20px;
    border: 2px solid var(--gray-400);
    border-top: none;
  }
  
  .transcription-page__timestamps h2 {
    margin-bottom: 1rem;
  }
  
  .timestamp-block {
    padding: 10px;
    margin-bottom: 1rem;
  }

  .timestamp-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }

  .timestamp-person {
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 6px; 
    color: var(--button-text);

    &.interviewee {
      background-color: var(--primary-dark);
    }

    &.interviewer {
      background-color: var(--secondary-dark);
    }

    &.speaker-1 {
      background-color: var(--primary-dark);
    }

    &.speaker-2 {
      background-color: var(--secondary-dark);
    }
  }
  
  .timestamp-time {
    color: var(--gray-2);
    background-color: var(--gray-7);
    width: fit-content;
    font-weight: bold;
  }
  
  .timestamp-text {
    color: var(--gray-7);
  }
  