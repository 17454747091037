

.gallery-sort {
  width: 80%;
  max-width: 1240px;
  margin: 0 auto;

  display: flex;
  gap: .5rem;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.sort-button {
  background-color: var(--primary-dark);
  color: var(--button-text);
  padding: 4px 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.gallery-seperator {
  width: 80%;
  max-width: 1240px;
  margin: 0 auto;
  height: 1px;
  background-color: var(--gray-700);
  margin-bottom: 1rem;
}

.gallery {
  width: 80%;
  max-width: 1240px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(360px, 1fr) minmax(360px, 1fr);
  grid-gap: 20px;
}