.auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2rem;
}

.auth-logo {
    width: 20%;
}

.auth-logo img {
    width: 100%;
}

.auth-container {
    display: flex;
    border-radius: 8px;
    overflow: hidden;

    background: var(--bg2);
    box-shadow: var(--shadow-me);

    max-height: 720px;
    width: 90%;
    max-width: 1200px;
}

.auth-container-image {
    flex: 2;
    
}

.auth-container-image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    
}

.auth-container-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    gap: 2rem;

    max-width: 480px;

    padding: 2rem;
}

.auth-header {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.auth-title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--gray-100);
}

.auth-description {
    font-size: 1.2rem;
    color: var(--gray-200);
}

.form-widget {
    width: 100%;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;

    gap: 1rem;
}

.inputContainer--row {
    flex-direction: row;
    justify-content: center;
    padding: 0 1rem;
}

.inputContainer label {
    font-size: 1rem;
    color: var(--gray-100);
    width: 80%;
}

.inputField {
    border: transparent;
    background-color: var(--bg-interaction);
    color: var(--gray-100);
    padding: .5rem 1rem;

    width: 80%;

    border-radius: 8px;
    margin-bottom: 1rem;
}

.inputField::placeholder {
    color: var(--gray-300);
}

.inputField:focus {
    outline: var(--secondary) auto 4px;
}

.controlContainer {
    margin: 0 auto;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 2rem;
}