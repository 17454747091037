.gallery a {
    text-decoration: none;
    color: var(--text)
  }

  .transcription {
    border: 1px solid var(--gray-800);
    background-color: var(--bg2);
    border-radius: 8px;
    padding: 1rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-shadow: var(--shadow-sm);
  }
  
  .transcription__title {
    font-size: 1.5rem;
    margin-bottom: 12px;
    padding: 0;
  }
  
  .transcription__summary {
    line-height: 1.25rem;
    flex: 1;
    margin-bottom: 16px;
    overflow: auto;
    max-height: 120px;

    padding-bottom: 1rem;

    border-bottom: 1px solid var(--gray-700);
  }

  
  .transcription__metadata {
    display: flex; 
    gap: 1rem; 
    font-size: 0.9rem;
  }

  .transcription__metadata-item {
    background-color: var(--primary-dark);
    color: var(--button-text);

    padding: 0.5rem;

    border-radius: 6px;
  }


  .transcription:hover {
    background-color: var(--bg-interaction);
    transition: background-color 0.3s ease;
  }