.container__upload {
    border-radius: 1.5rem;

    margin: 0 auto;
    padding: 2rem 0;
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 1rem;  
}

.upload-step {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr 2fr 1fr; /* Adjust this based on your needs */
    align-content: start;
    height: 520px; 
    width: 640px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--bg2);

    box-shadow: var(--shadow-sm);
}

.upload-information {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: .5rem;
}

.upload-step__title {
    font-size: 1.5rem;
    font-weight: 600;
}

.upload-inputs {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr 1fr 1fr;
}

.upload-inputs {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 1fr 1fr 1fr;
}


.upload-step__file-input {
    margin-top: .5rem;
    padding: 1rem;
    width: 100%;
    border-radius: 0.25rem;

    background-color: var(--gray-800);
    border-radius: 4px;

    border: 1px dashed var(--gray-300);
}

.upload-step__file-input::file-selector-button {
    background-color: var(--gray-300);
    color: var(--bg);
    border: transparent;

    padding: .25rem .5rem;
    border-radius: 4px;
}



.upload-step__filename {
    font-size: 1.2rem;
    font-weight: 500;
}

.upload-step__name-input, .upload-step__language-select {
    width: 100%;
    margin-top: .5rem;
    padding: 1rem;
    border-radius: 0.25rem;
    border: 1px solid var(--gray-300);
    background-color: var(--bg);
    color: var(--text);
}

.upload-step__next-button {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    color: #fff;
    cursor: pointer;
}

.upload-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}