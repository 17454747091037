.agbs-container {
    display: flex;
    flex-direction: column;

    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
    padding: 4rem 1rem;
}

