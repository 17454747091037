.App {
    min-height: 100svh; 
    min-height: 100vh; 

    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    color: var(--text);
}

.main {
    flex: 1;
    padding: 1rem;
}