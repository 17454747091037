.home {
    display: flex;
    flex-direction: column;
}

.toggle-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;

    width: 80%;
    max-width: 1240px; 

    border: .75px solid var(--gray-700);
    border-radius: 1rem;

    box-shadow: var(--shadow-sm); 
}

.toggle-upload-container:has(.toggle-upload--toggled) {
    box-shadow: var(--shadow-me);
}


.toggle-upload {
    width: 100%;
    padding: 1rem 2rem; 

    display: flex;
    gap: 1rem;
}

.toggle-icon {
    fill: var(--gray-100);
    transition: transform 0.5s; /* Add a transition for smoother rotation */    
}

.toggle-upload--toggled {
    border-bottom: .75px solid var(--gray-700);
}

.toggle-upload--toggled .toggle-icon {
    transform: rotate(180deg); /* Rotate the icon when toggled */
  }

#message {
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}