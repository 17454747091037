

.App--dark {
  --headings-font: "DM Sans", "DM Sans Placeholder", sans-serif;
  --body-font: "DM Sans", "DM Sans Placeholder", sans-serif;

  --bg: #1E193B;
  --bg2: #302B4F;
  --bg-interaction: #2a244e;
  --text: #f5f5f5;

  --button-text: #f5f5f5;

  --primary: #7358f6;
  --primary-dark: #4e38b9;
  --primary-light: #9c8de9;
  --primary-lightest: #d9d3f9;

  --secondary: #DE5494;
  --secondary-dark: #a72561;
  --secondary-light: #f56aab;

  --tertiary: #E8885D;
  --quaternary: #6A67F4;
  --quinary: #5A80F0;

  --alert: red;
  --warning: yellow;

  --gray-1000: #201E26;
  --gray-900: #2D2B32;
  --gray-800: #2B2C39;
  --gray-700: #35333A;
  --gray-600: #424258;
  --gray-500: #636265;
  --gray-400: #aeadae;
  --gray-300: #e8e8e8;
  --gray-200: #f1f1f1;
  --gray-100: #FFFFFF;

  --shadow-sm: 0px 1px 3px rgba(255, 255, 255, 0.12), 0 1px 2px rgba(255, 255, 255, 0.12);
  --shadow-me: 0px 3px 6px rgba(255, 255, 255, 0.15), 0px 2px 4px rgba(255, 255, 255, 0.12);
  --shadow-l: 0px 10px 20px rgba(255, 255, 255, 0.15), 0px 3px 6px rgba(255, 255, 255, 0.1);
  --shadow-xl: 0px 15px 25px rgba(255, 255, 255, 0.15), 0px 5px 10px rgba(255, 255, 255, 0.05);
  --shadow-xll: 0px 20px 40px rgba(255, 255, 255, 0.2);
}

.App--light {
    --headings-font: "DM Sans", "DM Sans Placeholder", sans-serif;
    --body-font: "DM Sans", "DM Sans Placeholder", sans-serif;
  
    --text: #101012;
    --bg-interaction: #e1dfdf;
    --bg2: #f5f5f5;
    --bg: #ebebeb;

    --button-text: #f5f5f5;
  
    --primary: #7358f6;
    --primary-dark: #4e38b9;
    --primary-light: #9c8de9;
    --primary-lightest: #d9d3f9;
  
    --secondary: #5570EA;
    --secondary-dark: #1636c8;
    --secondary-light: #6BBDFD;

    --tertiary: #43C8DE;
    --quaternary: #95E3DC;
    --quinary: #FF7865;

    --alert: red;
    --warning: yellow;
  
    --gray-100: #201E26;
    --gray-200: #2D2B32;
    --gray-300: #2B2C39;
    --gray-400: #35333A;
    --gray-500: #424258;
    --gray-600: #636265;
    --gray-700: #aeadae;
    --gray-800: #e8e8e8;
    --gray-900: #f1f1f1;
    --gray-1000: #FFFFFF;

    --shadow-sm: 0px 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12);
    --shadow-me: 0px 3px 6px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.12);
    --shadow-l: 0px 10px 20px rgba(0, 0, 0, 0.15), 0px 3px 6px rgba(0, 0, 0, 0.1);
    --shadow-xl: 0px 15px 25px rgba(0, 0, 0, 0.15), 0px 5px 10px rgba(0, 0, 0, 0.05);
    --shadow-xll: 0px 20px 40px rgba(0, 0, 0, 0.2);
  }